import React, { useState } from "react";
import './SearchBar.css';
import { CancelImg, SearchImg } from "../images";

type SearchBarProps = {
    onSearch: (searchValue: string) => void,
    onCancel: () => void
}

const SearchBar = (props: SearchBarProps) => {
    const {onSearch, onCancel} = props;
    const searchRef = React.createRef<HTMLInputElement>();
    const cancelRef = React.createRef<HTMLImageElement>();
    const [displayCancel, setDisplayCancel] = useState<string>("none");

    const search = () => {
        if(searchRef.current){
            if(searchRef.current.value.length > 0){
                onSearch(searchRef.current.value);
            }else{
                onCancel();
            }
        }
    }

    const cancel = () => {
        if(searchRef.current){
            searchRef.current.value = "";
            onCancel();
            hideCross();
        }
    }

    const hideCross = () => {
        if(displayCancel === "block"){
            cancelRef.current!.style.animationName = "hideButton";
            setTimeout(() => {
                setDisplayCancel("none");
            },400);
        }
    }

    const showCross = () => {
        if(displayCancel === "none"){
            cancelRef.current!.style.animationName = "showButton";
            setDisplayCancel("block");
        }
    }

    const checkKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "Enter" && searchRef.current){
            search();
            e.stopPropagation();
        }
        if(searchRef.current && searchRef.current.value.length > 0){
            showCross();
        }else{
            hideCross();
        }
    }

    return (
        <div className="search-bar" >
            <img alt="rechercher" src={SearchImg} onClick={search} className="search-button" />
            <input ref={searchRef} type="text" placeholder="Recherche" onKeyDown={checkKey} />
            <img alt="annuler recherche" ref={cancelRef} src={CancelImg} onClick={cancel} className="search-button" style={{display:displayCancel}}/>
        </div>
    );
}

export default SearchBar;