import React, {useEffect,useState,} from 'react';
import './QRCodeReader.css';
import {CameraDevice, Html5Qrcode, Html5QrcodeResult } from 'html5-qrcode'
import {GoBack} from '.';

// l'id de l'élément visuel où se trouvera le scanneur de qr code
const qrCodeLocation = "QRCodeReader";

type QRCodeReaderProps = {
    description: string,
    handleQrCodeRead: (decodedText: string) => void,
    onGoBack: () => void,
    valueToIncludeToValidateQrCode?: string
};

const QRCodeReader = (props : QRCodeReaderProps) => {
    const {description, handleQrCodeRead, onGoBack, valueToIncludeToValidateQrCode} = props;
    const [allCamera, setAllCamera] = useState<CameraDevice[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [currentView, setCurrentView] = useState<Html5Qrcode | undefined>();

    // l'id de la caméra
    const [currentCamera, setCurrentCamera] = useState<string | null>(null);

    // un scan a été réussi
    const onNewScanResult = (decodedText: string, decodedResult: Html5QrcodeResult, reader: Html5Qrcode) => {
        if(decodedText == null || decodedText === ""){
            return;
        }
        if(valueToIncludeToValidateQrCode === undefined || decodedText.includes(valueToIncludeToValidateQrCode)){
            reader.stop();
            handleQrCodeRead(decodedText);
        }
    };

    // appelé à chaque scan en echec (dépend de 'fps')
    const onErrorResult = (errorMessage, reader: Html5Qrcode) => {
    };

    // charge les caméras disponibles et utilise le 1er venu.
    useEffect(() => {
        Html5Qrcode.getCameras().then(devices => {
            devices.forEach(x => console.log(x.label))
            setAllCamera(devices);
            if(devices && devices.length > 0){
                // on prend le dernier car c'est souvent la caméra arrière
                // du téléphone qui est la plus utilisée
                setCurrentCamera(devices[devices.length - 1].id);
                setCurrentIndex(devices.length - 1);
            }else{
                console.log("no camerra found");
            }
        }).catch(err => {
            console.log("getCameras error : " + err);
        });
    }, []);

    // déclencher une fois qu'une caméra à été trouvé 
    useEffect(() => {
        if(currentCamera !== null){
            if(currentView){
                try {
                    currentView.stop();
                    console.log("stop camera");
                } catch (error) {
                    // Rien
                    console.log("error during camera stop");
                }
            }

            try {
                const html5QrCode = new Html5Qrcode(qrCodeLocation);
                html5QrCode.start(currentCamera, {
                    fps : 20, // nombre de scan par seconde
                    qrbox: 300 // taille de la boîte où se trouve le scanner 
                },
                (decodedText, decodedResult) => {
                    onNewScanResult(decodedText, decodedResult, html5QrCode);
                },
                (error) => {
                    onErrorResult(error, html5QrCode);
                });
                setCurrentView(html5QrCode);
                console.log("camera start");
            } catch (error) {
                // Rien
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCamera])

    const changeCamera = () => {
        let newIndex = currentIndex +1;
        if(newIndex === allCamera.length){
            newIndex = 0;
        }
        setCurrentIndex(newIndex);
        setCurrentCamera(allCamera[newIndex].id);
    }

    return (
        <div className="qrcode-main">
            <GoBack onGoBack={onGoBack} />
            <p className="qrcode-desc">{description}</p>
            <div className="qrcode-container">
                <div id={qrCodeLocation} />
            </div>
            
            {allCamera  && (
                <input onClick={() => {changeCamera()}} className='white-button qrcode-button' type='button' value='Changer caméra' />
            )}
        </div>
    );
}

export default QRCodeReader;