import React, { createRef, useEffect, useState } from "react";
import './ConsultSignatures.css';
import {GoBack} from "..";
import { ConsultTable } from "../../components";
import { ConsultTableElement, ConsultTableHeader, ConsultTableHeaderIcon } from "../../components/compTypes.ts";
import { AppTools, MeasureType, SignatureType, WorkOrderType, getDateOnly, getMeasureTypeLabel, getRoleLabel, getTimeOfDate } from "../../types.ts";
import useConsultSignature from "../../hooks/useConsultSignature.tsx";
import { GoBackImg, GoFrontmg } from "../../images/index.js";
import { MeasureReponse } from "../../api/responses.ts";

type ConsultSignaturesProps = {
    appTools: AppTools,
    workOrderId: number,
    onClose: () => void,
    customToken?: string
};

const getTypeSignName = (signType: SignatureType): string => {
    switch(signType){
        case SignatureType.HALF_DAY_MORNING:
            return "Matin";
        case SignatureType.HALF_DAY_AFTERNOON:
            return "Après-midi";
        case SignatureType.END_HALF_DAY_MORNING:
            return "Sortie matin";
        case SignatureType.END_HALF_DAY_AFTERNOON:
            return "Sortie après-midi";
        case SignatureType.WORKS_ACCEPTANCE:
            return "Acceptation travaux";
        default:
            return "<inconnue>";
    }
        
}

const getColorByWOType = (woType: WorkOrderType): string => {
    switch(woType){
        case WorkOrderType.WORK_PERMITS:
        case WorkOrderType.WORK_PERMITS_WITH_RECEPTIONIST:
        case WorkOrderType.WORK_PERMITS_WITH_RECEPTIONIST_AND_SECUTITY:
        case WorkOrderType.WORK_PERMITS_WITH_SECUTITY:
            return "linear-gradient(180deg, #3DBFF1 0%, #38ACD9 100%)";
        case WorkOrderType.WORK_PERMITS_HOT_SPOT:
            return "linear-gradient(180deg, #FF5253 0%, #DF4647 100%)";
        case WorkOrderType.COECOE:
            return "linear-gradient(180deg, #EACA3D 0%, #D2B638 100%)";
        case WorkOrderType.WORK_PERMIT_AT_HEIGHT:
        case WorkOrderType.LIFTING_PERMIT:
            return "linear-gradient(180deg, #FD9700 0%, #D88100 100%)";
        case WorkOrderType.DIGGING_PERMIT:
            return "linear-gradient(180deg, #00E161 0%, #02A448 100%)";
        case WorkOrderType.PENETRATION_PERMIT:
            return "linear-gradient(180deg, #AA00FD 0%, #7400AC 100%)";
        default:
            return "linear-gradient(180deg, #CBC9C9 0%, #9F9F9F 100%)";
    }
}

const getTitleByWOType = (woType: WorkOrderType): string => {
    switch(woType){
        case WorkOrderType.WORK_PERMITS:
        case WorkOrderType.WORK_PERMITS_WITH_RECEPTIONIST:
        case WorkOrderType.WORK_PERMITS_WITH_RECEPTIONIST_AND_SECUTITY:
        case WorkOrderType.WORK_PERMITS_WITH_SECUTITY:
            return "Autorisation travail";
        case WorkOrderType.WORK_PERMITS_HOT_SPOT:
            return "Permis de travail par point chaud";
        case WorkOrderType.COECOE:
            return "Consignation/condamnation électrique des équipements";
        case WorkOrderType.WORK_PERMIT_AT_HEIGHT:
            return "Autorisation travail en hauteur";
        case WorkOrderType.LIFTING_PERMIT:
            return "Permis de levage";
        case WorkOrderType.DIGGING_PERMIT:
            return "Permis de fouille";
        case WorkOrderType.PENETRATION_PERMIT:
            return "Permis de pénétration";
        default:
            return "<Permis inconnue>";
    }
}

const getPriorityDisplayOfWOType = (woType: WorkOrderType): number => {
    switch(woType){
        case WorkOrderType.WORK_PERMITS:
        case WorkOrderType.WORK_PERMITS_WITH_RECEPTIONIST:
        case WorkOrderType.WORK_PERMITS_WITH_RECEPTIONIST_AND_SECUTITY:
        case WorkOrderType.WORK_PERMITS_WITH_SECUTITY:
            return 1;
        case WorkOrderType.WORK_PERMITS_HOT_SPOT:
            return 2;
        case WorkOrderType.COECOE:
            return 3;
        case WorkOrderType.WORK_PERMIT_AT_HEIGHT:
            return 4;
        case WorkOrderType.LIFTING_PERMIT:
            return 5;
        case WorkOrderType.DIGGING_PERMIT:
            return 6;
        case WorkOrderType.PENETRATION_PERMIT:
            return 7;
        default:
            return 8;
    }
}

const getTitleByMeasureType = (measureType: MeasureType): string => {
    switch(measureType){
        case MeasureType.CommentExecutior:
            return "Commentaire GIES2";
        case MeasureType.CommentManager:
            return "Commentaire Exploitant";
        case MeasureType.CommentFireman:
            return "Commentaire Sécurité"
        case MeasureType.CommentElectrician:
            return "Commentaire Electricien";
        case MeasureType.CommentReceptionist:
            return "Commentaire DO / DOT";
        case MeasureType.Person:
            return "Nb travailleurs";
        default: 
            return "Mesure / Résultat";
    }
}

const getValueMeasure = (measure: MeasureReponse): string => {
    switch(measure.measureType){
        case MeasureType.CommentExecutior:
        case MeasureType.CommentManager:
        case MeasureType.CommentReceptionist:
        case MeasureType.CommentFireman:
        case MeasureType.CommentElectrician:
        case MeasureType.Person:
            return measure.measureValue;
        case MeasureType.Other:
            return measure.customMeasureType + " : " + measure.measureValue + " " + (measure.measureUnit ?? "");
        default:
            return getMeasureTypeLabel(measure.measureType) + " " + measure.measureValue + " " + (measure.measureUnit ?? "");
    }
}

const ConsultSignatures = (props : ConsultSignaturesProps) => {
    const { appTools, workOrderId, onClose, customToken } = props;
    
    const mainRef = createRef<HTMLDivElement>();
    const [nextPage, previousPage, currentSignatures, pageNumber, pageNumberMax, hasNext] = useConsultSignature(workOrderId, appTools, customToken);

    const [tables, setTables] = useState<ConsultTableElement[]>([]);
    const [valuesTable, setValuesTable] = useState<string[][]>([]);
    const [titleTable, setTitleTable] = useState<string>("");

    const triggerHideConsultSign = () => {
        mainRef.current!.style.animationName = "slideout";
        setTimeout(() => {
            if(mainRef.current){
                mainRef.current!.style.opacity = "0";
            }
            onClose();
        },400);
    }

    useEffect(() => {
        if(currentSignatures && currentSignatures.length > 0){
            // on trie les signatures par type de bon de travail
            const sortedSignatures = currentSignatures.sort((a, b) => getPriorityDisplayOfWOType(a.woType) - getPriorityDisplayOfWOType(b.woType));
            // on met à jour le titre de la table
            setTitleTable(sortedSignatures[0].signDate + " " + getTypeSignName(sortedSignatures[0].signatureType));

            const newValuesTable: string[][] = [];
            const newTables: ConsultTableElement[] = [];

            // on initialise les entêtes et les valeurs des tableaux
            // (par défaut chaque tableau contient la date de signature)
            let currentHeader: ConsultTableHeader[] = [{icon: ConsultTableHeaderIcon.DATE, title: "Heure signature"}];
            let currentValue: string[] = [getTimeOfDate(sortedSignatures[0].signDate)];
            // on initialise le type de bon de travail actuel
            // (cette variable va permettre de savoir si on doit créer un nouveau tableau)
            let currentWOType: WorkOrderType = sortedSignatures[0].woType;
            sortedSignatures.forEach((signature) => {
                // si le type de bon de travail est différent de celui actuel
                if(signature.woType !== currentWOType){
                    // le tableau actuel est terminé, on le sauvegarde
                    newValuesTable.push(currentValue);
                    newTables.push({
                        titleTable: getTitleByWOType(currentWOType), 
                        titleBackgroundCss: getColorByWOType(currentWOType), 
                        headers: currentHeader});
                    // on initialise un nouveau tableau
                    // (sans oublier d'ajouter la date de signature)
                    currentHeader = [{icon: ConsultTableHeaderIcon.DATE, title: "Heure signature"}];
                    currentValue = [getTimeOfDate(signature.signDate)];
                    currentWOType = signature.woType;
                }
                // on ajoute les informations au tableau actuel
                currentHeader.push({icon: ConsultTableHeaderIcon.PERSON, title: getRoleLabel(signature.userRoleId)});
                currentValue.push(signature.userLabelle);
                if(signature.measures){
                    signature.measures.forEach((measure) => {
                        currentHeader.push({icon: ConsultTableHeaderIcon.MEASURE, title: getTitleByMeasureType(measure.measureType)});
                        currentValue.push(getValueMeasure(measure));
                    });
                }
            });
            // on sauvegarde le dernier tableau
            newValuesTable.push(currentValue);
            newTables.push({
                titleTable: getTitleByWOType(currentWOType), 
                titleBackgroundCss: getColorByWOType(currentWOType), 
                headers: currentHeader});
            
            setTitleTable(getDateOnly(sortedSignatures[0].signDate) + " " + getTypeSignName(sortedSignatures[0].signatureType));
            setTables(newTables);
            setValuesTable(newValuesTable);
        }
        else{
            setTables([]);
            setValuesTable([]);
            setTitleTable("");
        }
    }, [currentSignatures])

    return (
        <div ref={mainRef} className="consultSign-main">
            <GoBack onGoBack={triggerHideConsultSign} />

            <div className="consultSign-wrapper">
                <div className="consultSign-container">
                    <ConsultTable 
                        backgroundTitleCss="linear-gradient(180deg, #CBC9C9 0%, #9F9F9F 100%)" 
                        title={titleTable} 
                        values={valuesTable} 
                        tables={tables}
                        appTools={appTools}/>
                </div>
            </div>

            <div className="consultSign-bottom">
                <img alt="retour arrière" src={GoBackImg} onClick={() => previousPage()} />
                {hasNext && (
                    <span>{pageNumber} / {pageNumberMax} et plus...</span>
                )}
                {!hasNext && (
                    <span>{pageNumber} / {pageNumberMax}</span>
                )}
                <img alt="page suivante" src={GoFrontmg} onClick={() => nextPage()} />
            </div>
        </div>
    );
}

export default ConsultSignatures;