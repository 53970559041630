import React from "react";
import './NbWorkerView.css';
import { EditWhiteImg } from "../../images";
import { AppTools, MessageViewType, InputType } from "../../types.ts";

type NbWorkerViewProps = {
    nbWorker: number | undefined,
    setNbWorker: (nbWorker: number) => void,
    appTools: AppTools
}

const NbWorkerView = (props: NbWorkerViewProps) => {
    const { nbWorker, setNbWorker, appTools } = props;

    const triggerEdit = () => {
        appTools.displayInput("Nombre de travailleur", "Inscrire le nombre de travailleur", InputType.number).then((val) => {
            const newNbWorker = val as number;
            if(newNbWorker < 1){
                appTools.showMessage(MessageViewType.Warning, "Le nombre de travailleur doit être supérieur à 0");
                return;
            }
            setNbWorker(newNbWorker);
        }).catch(() => {
            // l'utilisateur a annulé
        })
    }

    return (
    <div className="nb-worker-view-main">
        <p className="nb-worker-view-title">Veuillez renseigner le nombre de travailleur</p>
        <div className="nb-worker-view-container">
            {nbWorker === undefined ? 
            (<p className="nb-worker-view-txt nb-worker-view-no-worker">Pas de travailleur définit</p>) : 
            (<p className="nb-worker-view-txt">Nb travailleurs : 
                <span className="nb-worker-view-nb-worker">{nbWorker}</span>
            </p>)}
            <div className="nb-worker-view-button-edit">
                <img src={EditWhiteImg} alt="bouton edition" onClick={triggerEdit} />
            </div>
        </div>
    </div>
    )
}

export default NbWorkerView;