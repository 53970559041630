import React, { CSSProperties, useEffect, useState } from "react";
import './ConsultWorkOrderDetail.css';
import { AppTools, EquipmentsChanging, MeasureType, MessageViewType, Role, SignatureTypeDeclaration, WorkOrderDisplayType, WorkOrderState, displayWorkOrderDataIdentifier, getCommentByUserRole, getDescriptionStatus } from "../../types.ts";
import { useRequest } from "../../hooks/index.js";
import { BASE_URL, BLACK, BLUE_PRIMARY, GREEN_PRIMARY, PURPLE_PRIMARY, WHITE, YELLOW_PRIMARY } from "../../const.ts";
import { ApiError, CompanyResponse, NotSignedWorkOrdersResponse, UserInfoResponse, WorkOrderData, WorkOrderDetailReponse, WorkOrderResponse } from "../../api/responses.ts";
import { CheckGreenImg, EntryToSignBlueImg, ExitToSignBlueImg, FileImg, PdfImg, SignatureBlueImg, SignatureImg, UnfinishedWorkYellowImg, WaitingImg, WipYellowImg, WorkToBeApprovedPurpleImg } from "../../images/index.js";
import { CommentaryView, ConsultSignatures, DeclareSignature, EquipmentsEditButton, EquipmentsView, FormDisplay, GoBack, InfoDetails, MeasureView, NbWorkerView, PdfReader } from "../index.js";
import { MeasureModel, ValidateSignModel } from "../../api/model.ts";

type ConsultWorkOrderDetailProps = {
    appTools: AppTools,
    workOrder: WorkOrderDetailReponse,
    userInfo: UserInfoResponse,
    onFailToLoad: () => void,
    onValidateSign?: () => void,
    onGoBack: () => void,
    customToken?: string
}

const getChildrensWorkOrderFormated = (model: WorkOrderDetailReponse, specificIds?: number[]): {id: number, labelle: string}[] | undefined => {
    if(model.workOrderChildren){
        const result: {id: number, labelle: string}[] = [];

        model.workOrderChildren.forEach(child => {
            if(specificIds == null || specificIds.includes(child.workId)){
                result.push({id: child.workId, labelle: child.workLabelle});
            }
        });

        return result;
    }
    
    return undefined;
}

const displayCompanies = (companies: CompanyResponse[]): string => {
    return companies.map(x => x.companyName).join(", ");
}

const ConsultWorkOrderDetail = (props: ConsultWorkOrderDetailProps) => {
    const {appTools, workOrder, userInfo, onFailToLoad, onValidateSign, onGoBack, customToken} = props;
    const [, post, get] = useRequest(BASE_URL, props.appTools, customToken);
    const model = workOrder;
    const [signType, setSignType] = useState<SignatureTypeDeclaration | undefined>();
    const [currentRole, setCurrentRole] = useState<Role | undefined>();
    const [canAddOptionalSign, setCanAddOptionalSign] = useState<boolean>(false);

    const H4Datas: WorkOrderData[] | undefined = model.workOrderDatas?.filter(x => x.workOrderDataTypeDisplay === WorkOrderDisplayType.H4);
    const allWorkOrder: {id: number, labelle: string}[] = 
        [
            {id: workOrder.workId, labelle: workOrder.workLabelle}, 
            ...(workOrder.workOrderChildren ? getChildrensWorkOrderFormated(workOrder)! : [])
        ];

    const [measures, setMeasures] = useState<MeasureModel[]>([]);

    const [statusCss, setStatusCss] = useState<CSSProperties>({});
    const [imgStatus, setImgStatus] = useState<string>("");
    const [txtStatus, setTxtStatus] = useState<string>("");
    const [buttonDeclareCss, setButtonDeclareCss] = useState<CSSProperties>({});
    const [buttonDeclareTxt, setButtonDeclareTxt] = useState<string>("");
    const [buttonDeclareVisible, setButtonDeclareVisible] = useState<boolean>(false);
    const [declarePannelVisible, setDeclarePannelVisible] = useState<boolean>(false);
    const [workAcceptancePannelVisible, setWorkAcceptancePannelVisible] = useState<boolean>(false);
    const [workOrdersAvailableForOptional, setWorkOrdersAvailableForOptional] = useState<{id: number, labelle: string}[] | undefined>(undefined);
    const [nbWorker, setNbWorker] = useState<number | undefined>(undefined);
    const [commentary, setCommentary] = useState<string | undefined>(undefined);
    const [equipmentsChanging, setEquipmentsChanging] = useState<EquipmentsChanging>(new EquipmentsChanging());

    const getUserRole = (): Promise<Role> => {
        return new Promise((resolve, reject) => {
            let roles = userInfo.userRoleIds.filter(x => x !== Role.Admin);

            if(roles.length === 1){
                resolve(roles[0]);
            }
            else if(roles.length > 1){
                appTools.displayRoleSelector(roles).then((role) => {
                    resolve(role);
                }).catch(() => {
                    onFailToLoad();
                    reject();
                });
            }else{
                onFailToLoad();
                reject();
            }
        });
    }
    useEffect(() => {
        getUserRole().then((role) => {
            setCurrentRole(role);
        }).catch(() => {});
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(model !== undefined && currentRole !== undefined){
            if(model.workState === WorkOrderState.WIP && model.canInteractRoles)
            {
                setCanAddOptionalSign(model.canInteractRoles.includes(currentRole));
            }

            setTxtStatus(getDescriptionStatus(model.workState));
            switch(model.workState){
                case WorkOrderState.AwaitingDecision:
                    setImgStatus(WaitingImg);
                    setStatusCss({color: BLACK});
                    setSignType(SignatureTypeDeclaration.START_HALF_DAY);
                    setButtonDeclareVisible(true);
                    
                    setButtonDeclareTxt("Déclarer début demi-journée");
                    setButtonDeclareCss({color: WHITE, backgroundColor: BLUE_PRIMARY})
                    break;
                case WorkOrderState.EntryToSign:
                    setImgStatus(EntryToSignBlueImg);
                    setStatusCss({color: BLUE_PRIMARY});
                    setSignType(SignatureTypeDeclaration.VALIDATE);
                    setButtonDeclareVisible(true);

                    setButtonDeclareTxt("Valider ma signature");
                    setButtonDeclareCss({color: WHITE, backgroundColor: BLUE_PRIMARY})
                    break;
                case WorkOrderState.WIP:
                    setImgStatus(WipYellowImg);
                    setStatusCss({color: YELLOW_PRIMARY});
                    setSignType(SignatureTypeDeclaration.END_HALF_DAY);
                    setButtonDeclareVisible(true);

                    setButtonDeclareTxt("Déclarer fin demi-journée");
                    setButtonDeclareCss({color: WHITE, backgroundColor: YELLOW_PRIMARY})
                    break;
                case WorkOrderState.ExitToSign:
                    setImgStatus(ExitToSignBlueImg);
                    setStatusCss({color: BLUE_PRIMARY});
                    setSignType(SignatureTypeDeclaration.VALIDATE);
                    setButtonDeclareVisible(true);

                    setButtonDeclareTxt("Valider ma signature");
                    setButtonDeclareCss({color: WHITE, backgroundColor: BLUE_PRIMARY})
                    break;
                case WorkOrderState.UnfinishedWork:
                    setImgStatus(UnfinishedWorkYellowImg);
                    setStatusCss({color: YELLOW_PRIMARY});
                    setSignType(SignatureTypeDeclaration.START_HALF_DAY);
                    setButtonDeclareVisible(true);

                    setButtonDeclareTxt("Déclarer début demi-journée");
                    setButtonDeclareCss({color: WHITE, backgroundColor: BLUE_PRIMARY})
                    break;
                case WorkOrderState.WorksToBeApproved:
                    setImgStatus(WorkToBeApprovedPurpleImg);
                    setStatusCss({color: PURPLE_PRIMARY});
                    setSignType(SignatureTypeDeclaration.VALIDATE);
                    setButtonDeclareVisible(true);

                    setButtonDeclareTxt("Valider réception travaux");
                    setButtonDeclareCss({color: WHITE, backgroundColor: PURPLE_PRIMARY})
                    break;
                case WorkOrderState.WorksApproved:
                    setImgStatus(CheckGreenImg);
                    setStatusCss({color: GREEN_PRIMARY});
                    setButtonDeclareVisible(false);
                    break;
                case WorkOrderState.Optionnal:
                    setImgStatus(SignatureBlueImg);
                    setStatusCss({color: BLUE_PRIMARY});
                    setSignType(SignatureTypeDeclaration.VALIDATE);
                    setButtonDeclareVisible(true);

                    setButtonDeclareTxt("Valider ma signature");
                    setButtonDeclareCss({color: WHITE, backgroundColor: BLUE_PRIMARY})
                    break;
            }
        }else{
            if(currentRole !== undefined){
                onFailToLoad();
            }
        }
        // eslint-disable-next-line
    }, [model, currentRole]);

    const triggerDeclareSign = async () => {
        switch(signType){
            case SignatureTypeDeclaration.VALIDATE:
                props.appTools.isLoading(true);
                const measuresToSend: MeasureModel[] = [...measures];
                // si le manager a renseigné le nombre de travailleur, on l'ajoute dans les mesures
                if(nbWorker){
                    measuresToSend.push({
                        workIdConcerned: model!.workId,
                        measureType: MeasureType.Person,
                        measureValue: nbWorker.toString()
                    });
                }
                // si un commentaire a été renseigné, on l'ajoute dans les mesures
                if(commentary){
                    measuresToSend.push({
                        workIdConcerned: model!.workId,
                        measureType: getCommentByUserRole(await getUserRole()),
                        measureValue: commentary
                    });
                }

                const episChanging = equipmentsChanging.EPIsToDTO(model.workId);
                const epcsChanging = equipmentsChanging.EPCsToDTO(model.workId);

                const modelRequest: ValidateSignModel= {
                    workIdConcerned: model!.workId,
                    roleIdUser: currentRole as number,
                    measures: measuresToSend,
                    newNecessaryEPIs: episChanging.episToAdd,
                    newNecessaryEPCs: epcsChanging.epcsToAdd,
                    necessaryEPIsToRemove: episChanging.episToRemove,
                    necessaryEPCsToRemove: epcsChanging.epcsToRemove
                }
                post("/signature/validate", modelRequest).then(() => {
                    onValidateSign!();
                }).catch((err : ApiError) => {
                    props.appTools.showMessage(MessageViewType.Error, err.message);
                }).finally(() => {
                    props.appTools.isLoading(false);
                });
                break;
            case SignatureTypeDeclaration.START_HALF_DAY:
            case SignatureTypeDeclaration.END_HALF_DAY:
            case SignatureTypeDeclaration.WORK_ACCEPTANCE:
                setDeclarePannelVisible(true);
                break;
        }
    }

    const triggerDeclareOptionalSign = () => {
        get<NotSignedWorkOrdersResponse>("/signature/notSigned/" + model!.workId).then((response) => {
            if(response.data.notSignedWorkIds.length > 0){
                setWorkOrdersAvailableForOptional(
                    getChildrensWorkOrderFormated(model!, response.data.notSignedWorkIds)!
                );
            }else{
                props.appTools.showMessage(MessageViewType.Error, "Aucun permis de travail à signer");
            }
        }).catch((err : ApiError) => {
            props.appTools.showMessage(MessageViewType.Error, err.message);
        });
    }

    const openFormEditor = (workOrder: WorkOrderResponse) => {
        props.appTools.pushDisplay(<FormDisplay 
            workOrder={workOrder} 
            onClose={() => props.appTools.popDisplay()} 
            appTools={appTools}
            customToken={customToken}
            />);
    }

    const openPdfWorkOrder = (pdfToDisplay: string) => {
        props.appTools.pushDisplay(<PdfReader 
            customToken={customToken}
            appTools={props.appTools}
            fileName={pdfToDisplay} 
            onClose={() => props.appTools.popDisplay()} />)
    };

    return (
        <div className='wodetail-main fill-to-screen'>
        <GoBack onGoBack={onGoBack} />
        <div className='wodetail-wrapper'>
            {model && currentRole && (
                <div className='wodetail-container'>
                    <h1 className='wodetail-title'>{model.workLabelle}</h1>
                    <p className='wodetail-subtitle'>N° Permis : {model.workId}</p>
                    {model.companiesAffilied && (
                        <p className='wodetail-building'>Entreprise(s) : {displayCompanies(model.companiesAffilied)}</p>
                    )}
                    
                    {/* affichage des sous-titre données par Petra */}
                    {model.workOrderDatas?.filter(x => x.workOrderDataTypeDisplay === WorkOrderDisplayType.H2)
                        .map((data) => (
                        <p className='wodetail-subtitle' key={data.workOrderDataId}>{displayWorkOrderDataIdentifier(data)}{data.workOrderDataContent}</p>
                    ))}
                    <div className='wodetail-status-container'>
                        <img alt="Status bon de travaux" className='wodetail-status-img' src={imgStatus} />
                        <p className='wodetail-status-txt' style={statusCss} >{txtStatus}</p>
                    </div>
                    <div className='wodetail-separator' />

                    {/* affichage d'info données par Petra */}
                    {model.workOrderDatas?.filter(x => x.workOrderDataTypeDisplay === WorkOrderDisplayType.H3)
                        .map((data) => (
                        <p className='wodetail-info' key={data.workOrderDataId}>{displayWorkOrderDataIdentifier(data)}{data.workOrderDataContent}</p>
                    ))}
                    
                    {/* Affichage des équipements nécessaire pour le ou les individus (EPI) */}

                    {model.necessaryEPIs && model.necessaryEPIs.length !== 0 && (
                        <div className='wodetail-security-container'>
                            <EquipmentsView appTools={appTools} workOrderId={model.workId} EPIs={model.necessaryEPIs} />
                        </div>
                    )}

                    {/* Affichage des équipements nécessaire sur place (EPC) */}

                    {model.necessaryEPCs && model.necessaryEPCs.length !== 0 && (
                        <div className='wodetail-security-container'>
                            <EquipmentsView appTools={appTools} workOrderId={model.workId} EPCs={model.necessaryEPCs} />
                        </div>
                    )}

                    {((model.necessaryEPCs && model.necessaryEPCs.length !== 0) || 
                    (model.necessaryEPIs && model.necessaryEPIs.length !== 0)) && (
                        <div className='wodetail-separator' />
                    )}
                    
                    {/* Les données de Petra à afficher uniquement quand l'utilisateur le demande */}

                    {H4Datas && H4Datas.length !== 0 && (
                        <>
                            <InfoDetails title="Détails" infos={H4Datas.map(x => displayWorkOrderDataIdentifier(x) + x.workOrderDataContent)} customStyle={{marginTop:"20px"}} />
                            <div className='wodetail-separator' />
                        </>
                    )}

                    {/* Annexes */}

                    {model.workOrderChildren && model.workOrderChildren.length !== 0 && (
                        <p className="wodetail-annexe-title">Annexes</p>
                    )}

                    {model.workOrderChildren && model.workOrderChildren.map((child, index) => {
                        if(child.containFormData){
                            return (
                                <div key={index} className='petra-button wodetail-button-icon' onClick={() => openFormEditor(child)}>
                                    <img alt="Annexe" src={FileImg} /> <p>{child.workLabelle}</p>
                                </div>
                            )
                        }else{
                            return (
                                <div key={index} className='petra-button wodetail-button-icon' onClick={() => openPdfWorkOrder(child.workPDF)}>
                                    <img alt="Annexe en format PDF" src={PdfImg} /> <p>{child.workLabelle}</p>
                                </div>
                            )
                        }
                    })}

                    {model.workOrderChildren && model.workOrderChildren.length !== 0 && (
                        <div className='wodetail-separator' />
                    )}

                    {signType === SignatureTypeDeclaration.VALIDATE && (
                        <>
                            {/* Les commentaires */}
                            <div style={{marginTop:"20px"}}/>
                            <CommentaryView 
                                commentary={commentary}
                                setCommentary={setCommentary}
                                appTools={appTools}
                                />
                            <div className='wodetail-separator' />
                        </>
                    )}

                    {signType === SignatureTypeDeclaration.VALIDATE && 
                        ((currentRole === Role.Fireman && (
                        <>
                            {/* Les mesures et les changements d'équipements
                             peuvent être déclarer uniquement par les pompier */}
                            <EquipmentsEditButton 
                                necessaryEPCs={model.necessaryEPCs}
                                necessaryEPIs={model.necessaryEPIs}
                                appTools={appTools}
                                equisChanging={equipmentsChanging}
                                setEquisChanging={setEquipmentsChanging}
                                />
                            <div className='declsign-separator' />
                            <MeasureView 
                                userRoleId={currentRole}
                                workOrders={allWorkOrder}
                                appTools={appTools}
                                measures={measures}
                                setMeasures={setMeasures}
                                customToken={customToken}
                                />
                            <div className='wodetail-separator' />
                        </>
                        )) || (currentRole === Role.Manager && model.workState === WorkOrderState.EntryToSign && (
                        <>
                            {/** Vue réservé à l'exploitant qui doit renseigner le nombre de 
                             * travailleur */}
                            <div style={{marginTop:"20px"}}/>
                            <NbWorkerView
                                nbWorker={nbWorker}
                                setNbWorker={setNbWorker}
                                appTools={appTools}
                                />
                            <div className='wodetail-separator' />
                        </>
                        )))}

                    {/* Boutton de consultation du permis de travail */}

                    {model && model.workPDF && (
                        <div className='petra-button wodetail-button-icon' onClick={() => openPdfWorkOrder(model.workPDF!)}>
                            <img alt="Permis en format PDF" src={PdfImg} /> <p>Autorisation travail</p>
                        </div>
                    )}

                    {/* Historique des signatures */}

                    {model.workState !== WorkOrderState.AwaitingDecision && (
                        <div className='petra-button wodetail-button-icon' 
                        onClick={() => {appTools.pushDisplay(<ConsultSignatures customToken={customToken} appTools={appTools} workOrderId={model.workId} onClose={appTools.popDisplay} />)}}>
                            <img alt="Historique signature" src={SignatureImg} /> <p>Historique signatures</p>
                        </div>
                    )}

                    {/* Bouton de déclaration optionnelle */}

                    {canAddOptionalSign && (
                        <div className='petra-button wodetail-button-declare' onClick={() => {triggerDeclareOptionalSign()}} style={{backgroundColor: BLUE_PRIMARY, color: WHITE}}>
                            <p>Déclarer signature optionnelle</p>
                        </div>
                    )}

                    {/* Bouton de déclaration */}
                    {buttonDeclareVisible && (
                        <div onClick={triggerDeclareSign} className='petra-button wodetail-button-declare' style={buttonDeclareCss}>
                            <p>{buttonDeclareTxt}</p>
                        </div>
                    )}

                    {model.workState === WorkOrderState.UnfinishedWork && (
                        <div className='petra-button wodetail-button-declare' onClick={() => {setWorkAcceptancePannelVisible(true)}}
                             style={{backgroundColor: GREEN_PRIMARY, color: WHITE}}>
                            <p>Déclarer réception travaux</p>
                        </div>
                    )}

                    <div className='wodetail-bottom' />

                </div>
            )}
        </div>

        {/* Panneau de déclaration demi-journée */}

        {declarePannelVisible && signType && (
            <DeclareSignature 
                customToken={customToken}
                appTools={appTools}
                userRoles={userInfo.userRoleIds} 
                onClose={() => {setDeclarePannelVisible(false)}} 
                mainWorkOrderLabelle={model.workLabelle}
                mainWorkOrder={model} declarationType={signType}
                workOrderChildrens={getChildrensWorkOrderFormated(model)} />
        )}

        {/* Panneau de déclaration optionnelle */}

        {workOrdersAvailableForOptional && (
            <DeclareSignature 
                customToken={customToken}
                appTools={props.appTools}
                userRoles={userInfo.userRoleIds} 
                onClose={() => {setWorkOrdersAvailableForOptional(undefined);}} 
                mainWorkOrderLabelle={model.workLabelle}
                mainWorkOrder={model} 
                declarationType={SignatureTypeDeclaration.OPTIONNAL}
                workOrderChildrens={workOrdersAvailableForOptional}
                />
        )}

        {/* Panneau de déclaration reception des travaux */}

        {workAcceptancePannelVisible && (
            <DeclareSignature 
                customToken={customToken}
                appTools={props.appTools}
                userRoles={userInfo.userRoleIds} 
                onClose={() => {setWorkAcceptancePannelVisible(false)}} 
                mainWorkOrderLabelle={model.workLabelle}
                mainWorkOrder={model} declarationType={SignatureTypeDeclaration.WORK_ACCEPTANCE}
                />
        )}
    </div>
    )
}

export default ConsultWorkOrderDetail;