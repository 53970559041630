import React, { CSSProperties, useEffect, useState } from "react";
import './MeasureView.css';
import { IsMeasurableWorkOrdersModel, MeasureModel } from "../../api/model.ts";
import { AddWhiteImg, EditWhiteImg } from "../../images";
import { AppTools, MeasureType, getMeasureTypeLabel } from "../../types.ts";
import { MeasureEditor } from "..";
import useRequest from "../../hooks/useRequest.tsx";
import { BASE_URL } from "../../const.ts";
import { MeasurableWorkOrderResponse } from "../../api/responses.ts";

type MeasureViewProps = {
    userRoleId: number,
    workOrders: {id: number, labelle: string}[],
    measures: MeasureModel[],
    setMeasures: (measures: MeasureModel[]) => void,
    appTools: AppTools,
    isCenterTitle?: boolean,
    customToken?: string
}

const MeasureView = (props: MeasureViewProps) => {
    const { userRoleId, workOrders, measures, setMeasures, appTools, isCenterTitle, customToken } = props;

    const [headerCss, setHeaderCss] = useState<CSSProperties>({});

    const [postWithResponse] = useRequest(BASE_URL, appTools, customToken);

    const openEditor = () => {
        // on va vérifier les bons qui peuvent recevoir des mesures
        appTools.isLoading(true);
        const request: IsMeasurableWorkOrdersModel = {
            roleIdUser: userRoleId,
            workOrderIds: props.workOrders.map((workOrder) => workOrder.id)
        };

        postWithResponse<MeasurableWorkOrderResponse[]>(`/workOrders/measurable`, request).then((response) => {
            const measurableWorkOrders: {id: number, labelle: string}[] = workOrders.filter((workOrder) => {
                const index = response.findIndex((r) => r.workId === workOrder.id);
                return index !== -1 && response[index].isMeasurable;
            });
            appTools.pushDisplay(
                <MeasureEditor 
                    workOrders={measurableWorkOrders}
                    measures={measures}
                    setMeasures={setMeasures}
                    appTools={appTools}
                />);
        }).finally(() => {
            appTools.isLoading(false);
        });
        // TODO : gérer le catch
    }

    useEffect(() => {
        if(isCenterTitle){
            setHeaderCss({textAlign: "center", fontWeight: "normal", marginTop: "0px"});
        }else{
            setHeaderCss({textAlign: "left", fontWeight: "bold", marginTop: "0px"});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCenterTitle])

    return (
        <div className="measure-main">
            <p className="measure-header" style={headerCss}>Mesures</p>
            {measures.length === 0 && (
                <p className="measure-no-data">Aucune mesure</p>
            )}

            {measures.map((measure, index) => {
                return(
                    <div key={index} className="measure-item">
                        {measure.measureType === MeasureType.Other && (
                            <>
                                <p className="measure-type">{measure.customMeasureType} : </p>
                                <p className="measure-value">{measure.measureValue}</p>
                                <p className="measure-unit">{measure.measureUnit}</p>
                            </>
                        )}
                        {measure.measureType !== MeasureType.Other && (
                            <>
                                <p className="measure-type">{getMeasureTypeLabel(measure.measureType)} : </p>
                                <p className="measure-value">{measure.measureValue}</p>
                                <p className="measure-unit">{measure.measureUnit}</p>
                            </>
                        )}
                    </div>
                )
            })}

            {measures.length > 0 && (
                <div className="measure-button measure-button-edit" onClick={openEditor}>
                    <img alt="boutton modifier" src={EditWhiteImg} />
                    <p>Modifier</p>
                </div>
            )}

            {measures.length === 0 && (
                <div className="measure-button measure-button-add" onClick={openEditor}>
                    <img alt="boutton ajouter" src={AddWhiteImg} />
                    <p>Ajouter</p>
                </div>
            )}
        </div>
    )
};

export default MeasureView;