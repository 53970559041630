import React, { CSSProperties, createRef, useEffect, useState } from "react";
import './MySelect.css';
import { ChevronDownImg } from "../../images";

type MySelectProps = {
    cssSelect?: CSSProperties,
    isFirstOptionNoValue?: boolean,
    options: {value: any, label: string}[],
    value: any,
    setSelectedValue: (value: any) => void
}

const heightMaxSelector = 500;

const MySelect = (props: MySelectProps) => {
    const {cssSelect, isFirstOptionNoValue, options, value, setSelectedValue } = props;
    const selectRef = createRef<HTMLDivElement>();
    const optionsContainerRef = createRef<HTMLDivElement>();

    const [selectedCss, setSelectedCss] = useState<CSSProperties>();
    const [optionsContainerCss, setOptionsContainerCss] = useState<CSSProperties>({display: "none"});
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    useEffect(() => {
        optionsContainerRef.current!.style.width =  selectRef.current!.offsetWidth + "px";
        for (let index = 0; index < options.length; index++) {
            if(options[index].value === value){
                setSelectedIndex(index);
                break;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const openOptions = () => {
        const rect: DOMRect = selectRef.current!.getBoundingClientRect();
        
        let top = rect.top;
        let height = rect.height * options.length;
        if(height > heightMaxSelector){
            height = heightMaxSelector;
        }

        const excedent = (rect.top + height) - window.innerHeight;
        
        if(excedent > 0){
            top = top - excedent;
        }

        setOptionsContainerCss({display: "block", left: rect.left, top: top, width: rect.width, height: height});
        setSelectedCss({opacity: 0 });
    };

    const closeOptions = () => {
        setOptionsContainerCss({display: "none"});
        setSelectedCss({opacity: 1 });
    };

    const changeSelectedIndex = (index: number) => {
        setSelectedIndex(index);
        closeOptions();
        setSelectedValue(options[index].value);
    };

    return (
        <div style={cssSelect} className="myselect-main">
            <div ref={selectRef} className="myselect-select" style={selectedCss} onClick={openOptions}>
                {isFirstOptionNoValue && selectedIndex === 0 && (
                    <p className="myselect-no-selected">{options[selectedIndex].label}</p>
                )}
                {(!isFirstOptionNoValue || selectedIndex !== 0) && (
                    <p>{options[selectedIndex].label}</p>
                )}
                <img alt="flèche d'ouverture" src={ChevronDownImg} />
            </div>
            <div ref={optionsContainerRef} className="myselect-options-container" style={optionsContainerCss}>
                {options.map((option, index) => {
                    if(isFirstOptionNoValue && index === 0){
                        return (
                            <div key={index} className="myselect-options" onClick={() => changeSelectedIndex(index)}>
                                <p className="myselect-no-selected">{option.label}</p>
                            </div>
                        )
                    }else{
                        return (
                            <div key={index} className="myselect-options" onClick={() => changeSelectedIndex(index)}>
                                <p>{option.label}</p>
                            </div>
                        )
                    }
                    
                })}
            </div>
        </div>
    )
};

export default MySelect;
