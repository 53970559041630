import React, { createRef, useEffect, useState } from "react";
import './MeasureEditor.css';
import { MeasureModel } from "../../api/model";
import { AppTools, MeasureType, MessageViewType, InputType } from "../../types.ts";
import { GoBack } from "./..";
import { AddWhiteImg, CrossImg } from "../../images";
import { MySelect } from "../../components";

type MeasureEditorProps = {
    workOrders: {id: number, labelle: string}[],
    measures: MeasureModel[],
    setMeasures: (measures: MeasureModel[]) => void,
    appTools: AppTools
}

const options = [
    {value: MeasureType.Null, label: "Type"},
    {value: MeasureType.Oxygen, label: "O2"},
    {value: MeasureType.ExplosiveGas, label: "Explo"},
    {value: MeasureType.DioxydeCarbon, label: "CO2"},
    {value: MeasureType.HydrogenSulfide, label: "H2S"},
    {value: MeasureType.Other, label: "Autre"},
];

const MeasureEditor = (props: MeasureEditorProps) => {
    const { workOrders, measures, setMeasures, appTools } = props;
    const [orderedMeasures, setOrderedMeasures] = useState<MeasureModel[][] | undefined>();
    const orderedWorkOrders = workOrders.sort((a, b) => a.id - b.id);
    const [allTypeMeasureSelected, setAllTypeMeasureSelected] = useState<MeasureType[][] | undefined>();

    const mainRef = createRef<HTMLDivElement>();

    const triggerCloseEditor = () => {
        mainRef.current!.style.animationName = "slideout";
        setTimeout(() => {
            if(mainRef.current){
                mainRef.current!.style.opacity = "0";
            }
            appTools.popDisplay();
        },400);
    }

    const addMeasure = (indexWorkOrder: number) => {
        const newAllTypeMeasureSelected = [...allTypeMeasureSelected!];
        newAllTypeMeasureSelected[indexWorkOrder].push(MeasureType.Null);
        setAllTypeMeasureSelected(newAllTypeMeasureSelected);

        const newOrderedMeasures = [...orderedMeasures!];
        newOrderedMeasures[indexWorkOrder].push({workIdConcerned: orderedWorkOrders[indexWorkOrder].id, measureType: MeasureType.Null, measureValue: "", measureUnit: ""});
        setOrderedMeasures(newOrderedMeasures);
    }

    const deleteMeasure = (indexWorkOrder: number, index: number) => {
        const newAllTypeMeasureSelected = [...allTypeMeasureSelected!];
        newAllTypeMeasureSelected[indexWorkOrder].splice(index, 1);
        setAllTypeMeasureSelected(newAllTypeMeasureSelected);

        const newOrderedMeasures = [...orderedMeasures!];
        newOrderedMeasures[indexWorkOrder].splice(index, 1);
        setOrderedMeasures(newOrderedMeasures);
    }

    const changeSelectedType = (indexWorkOrder: number, index: number, newValue: MeasureType) => {
        let newAllTypeMeasureSelected = [...allTypeMeasureSelected!];

        if(newValue === MeasureType.Other){
            appTools.displayInput("Écrire le type de mesure", "Type de mesure", InputType.string).then((value) => {
                newAllTypeMeasureSelected[indexWorkOrder][index] = newValue;
                setAllTypeMeasureSelected(newAllTypeMeasureSelected);
                setOrderedMeasures(prev => 
                {
                    let newOrderedMeasures = [...prev!];
                    newOrderedMeasures[indexWorkOrder][index].customMeasureType = value as string;
                    return newOrderedMeasures;
                });
                autoCompletUnit(indexWorkOrder, index, newValue);
            }).catch(() => {
                setAllTypeMeasureSelected(newAllTypeMeasureSelected);
                appTools.showMessage(MessageViewType.Warning, "Veuillez saisir un type de mesure");
            });
        }else{
            newAllTypeMeasureSelected[indexWorkOrder][index] = newValue;
            setAllTypeMeasureSelected(newAllTypeMeasureSelected);
            setOrderedMeasures(prev => 
            {
                let newOrderedMeasures = [...prev!];
                newOrderedMeasures[indexWorkOrder][index].customMeasureType = undefined;
                return newOrderedMeasures;
            });
            autoCompletUnit(indexWorkOrder, index, newValue);
        }
    }

    const autoCompletUnit = (indexWorkOrder: number, index: number, newValue: MeasureType) => {
        let autoCompletUnit: string | undefined = undefined;
        switch(newValue){
            case MeasureType.Oxygen:
                autoCompletUnit = "%";
                break;
            case MeasureType.ExplosiveGas:
                autoCompletUnit = "% LIE";
                break;
            case MeasureType.DioxydeCarbon:
            case MeasureType.HydrogenSulfide:
            case MeasureType.Other:
                autoCompletUnit = "PPM";
                break;
        }
        if(autoCompletUnit){
            changeUnitMeasure(indexWorkOrder, index, autoCompletUnit);
        }
    }

    const changeValueMeasure = (indexWorkOrder: number, index: number, newValue: string) => {
        setOrderedMeasures(prev => 
        {
            let newOrderedMeasures = [...prev!];
            newOrderedMeasures[indexWorkOrder][index].measureValue = newValue;
            return newOrderedMeasures;
        });
    }

    const changeUnitMeasure = (indexWorkOrder: number, index: number, newValue: string) => {
        setOrderedMeasures(prev => 
        {
            let newOrderedMeasures = [...prev!];
            newOrderedMeasures[indexWorkOrder][index].measureUnit = newValue;
            return newOrderedMeasures;
        });
    }

    useEffect(() => {
        const newAllTypeMeasureSelected: MeasureType[][] = [];
        const newOrderedMeasures: MeasureModel[][] = [];

        workOrders.forEach(workOrder => {
            const measuresOfWorkOrder = measures.filter(measure => measure.workIdConcerned === workOrder.id);
            newOrderedMeasures.push(measuresOfWorkOrder);

            const newAllTypeMeasureSelectedOfWorkOrder: MeasureType[] = [];

            measuresOfWorkOrder.forEach(measure => {
                newAllTypeMeasureSelectedOfWorkOrder.push(measure.measureType);
            })

            newAllTypeMeasureSelected.push(newAllTypeMeasureSelectedOfWorkOrder);
        })

        setAllTypeMeasureSelected(newAllTypeMeasureSelected);
        setOrderedMeasures(newOrderedMeasures);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const saveMeasures = () => {
        if(allTypeMeasureSelected){
            for (let index = 0; index < allTypeMeasureSelected.length; index++) {
                for (let index2 = 0; index2 < allTypeMeasureSelected[index].length; index2++) {
                    if(allTypeMeasureSelected[index][index2] === MeasureType.Null){
                        appTools.showMessage(MessageViewType.Warning, "Veuillez selectionner un type de mesure");
                        return;
                    }
                    if(orderedMeasures![index][index2].measureValue === ""){
                        appTools.showMessage(MessageViewType.Warning, "Veuillez saisir une valeur pour la mesure");
                        return;
                    }
                }
            }

            const newMeasures: MeasureModel[] = [];
            for (let index = 0; index < allTypeMeasureSelected.length; index++) {
                for (let index2 = 0; index2 < allTypeMeasureSelected[index].length; index2++) {
                    newMeasures.push({
                        workIdConcerned: orderedWorkOrders[index].id,
                        measureType: allTypeMeasureSelected[index][index2],
                        measureValue: orderedMeasures![index][index2].measureValue,
                        measureUnit: orderedMeasures![index][index2].measureUnit,
                        customMeasureType: orderedMeasures![index][index2].customMeasureType
                    })
                }
            }
            setMeasures(newMeasures);
            triggerCloseEditor();
        }
    }

    return (
        <div ref={mainRef} className="measure-editor-main">
            <GoBack onGoBack={triggerCloseEditor} />
            <div className="measure-editor-wrapper">
                <div className="measure-editor-container">
                    <p className="measure-editor-header">Mesures</p>
                    <p className="measure-editor-subheader">Pour chaque mesure, le type et la valeur est obligatoire. L'unité est optionnel</p>
                    
                    {orderedMeasures && allTypeMeasureSelected && orderedWorkOrders.map((workOrder, index) => {
                        return (
                            <div key={index} className="measure-editor-wo-container">
                                <p className="measure-editor-wo-title">{workOrder.labelle}</p>
                                <div className="measure-editor-separator" />

                                {orderedMeasures[index].map((measure, index2) => {
                                    return (
                                        <div key={1000 * index + index2} className="measure-editor-item-container">
                                            <img alt="bouton de suppression" src={CrossImg} onClick={() => deleteMeasure(index, index2)}/>

                                            <MySelect
                                                cssSelect={{width: "25%"}}
                                                isFirstOptionNoValue={true}
                                                options={options}
                                                value={allTypeMeasureSelected[index][index2]}
                                                setSelectedValue={(value) => {changeSelectedType(index, index2, value)}}
                                                />

                                            <input 
                                                onChange={(e) => {changeValueMeasure(index, index2, e.target.value)}} 
                                                value={measure.measureValue} placeholder="valeur" />
                                            <input 
                                                onChange={(e) => {changeUnitMeasure(index, index2, e.target.value)}}
                                                value={measure.measureUnit} placeholder="unité" />
                                        </div>
                                    )
                                })}
                                
                                <div className="measure-editor-button-add" onClick={() => addMeasure(index)}>
                                    <img alt="bouton d'ajout" src={AddWhiteImg} />
                                    <p>Ajouter</p>
                                </div>
                            </div>
                        )
                    })}

                    <div className="white-button measure-save-button" onClick={saveMeasures}>
                        <p>Sauvegarder</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MeasureEditor;