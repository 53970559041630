import React, { CSSProperties, useEffect, useState } from "react";
import './EquipmentsEditButton.css';
import { EditWhiteImg } from "../../images";
import { AppTools, EquipmentsChanging } from "../../types";
import { EPCResponse, EPIResponse } from "../../api/responses";
import { GREEN_PRIMARY, RED_PRIMARY } from "../../const.ts";
import EditEquipmentsView from "./EditEquipmentsView.tsx";

type EquipmentsEditButtonProps = {
    appTools: AppTools,
    necessaryEPIs?: EPIResponse[],
    necessaryEPCs?: EPCResponse[],
    equisChanging: EquipmentsChanging,
    setEquisChanging: React.Dispatch<React.SetStateAction<EquipmentsChanging>>,
    isCenterTitle?: boolean,
    customToken?: string
}

const count =<T,> (array?: T[], compare?: (element: T) => boolean): number => {
    let c = 0;  
    if(array === undefined || compare === undefined){
        return c;
    }
    for (let index = 0; index < array.length; index++) {
       if(compare(array[index])){
            c++;
       }
    }
    return c;
}

const EquipmentsEditButton = (props: EquipmentsEditButtonProps) => {
    const { appTools, necessaryEPIs, necessaryEPCs, equisChanging, setEquisChanging, isCenterTitle, customToken } = props;
    const [headerCss, setHeaderCss] = useState<CSSProperties>({});

    const nbAdd = count(equisChanging.epis, x => x.toAdd) + count(equisChanging.epcs, x => x.toAdd);
    const nbRemove = count(equisChanging.epis, x => !x.toAdd) + count(equisChanging.epcs, x => !x.toAdd);

    useEffect(() => {
        if(isCenterTitle){
            setHeaderCss({textAlign: "center", fontWeight: "normal", marginTop: "0px"});
        }else{
            setHeaderCss({textAlign: "left", fontWeight: "bold", marginTop: "12px"});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openEditor = () => {
        appTools.pushDisplay(
        <EditEquipmentsView
            appTools={appTools}
            necessaryEPCs={necessaryEPCs}
            necessaryEPIs={necessaryEPIs}
            equisChanging={equisChanging}
            setEquisChanging={setEquisChanging}
            customToken={customToken}
             />)
    }

    return (
        <div className="equipmentsEdit-main">
            <p className="equipmentsEdit-header" style={headerCss}>EPI / EPC</p>

            <div className="equipmentsEdit-button" onClick={openEditor}>
                <div className="equipmentsEdit-badges-container">
                    {nbAdd > 0 && (
                        <div style={{backgroundColor: GREEN_PRIMARY}}>
                            + {nbAdd}
                        </div>
                    )}
                    {nbRemove > 0 && (
                        <div style={{backgroundColor: RED_PRIMARY}}>
                            - {nbRemove}
                        </div>
                    )}
                </div>
                <img alt="boutton modifier" src={EditWhiteImg} />
                <p>Changer EPI / EPC</p>
            </div>
        </div>
    )   
}

export default EquipmentsEditButton;