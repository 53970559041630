import React, { CSSProperties, useEffect, useState } from "react";
import './CommentaryView.css';
import { AppTools, InputType } from "../../types.ts";
import { EditWhiteImg, TrashWhiteImg } from "../../images";

type CommentaryViewProps = {
    commentary: string | undefined;
    setCommentary: (commentary: string | undefined) => void;
    appTools: AppTools;
    isCenterTitle?: boolean;
}

const CommentaryView = (props: CommentaryViewProps) => {
    const { commentary, setCommentary, appTools, isCenterTitle } = props;

    const [headerCss, setHeaderCss] = useState<CSSProperties>({});

    const triggerEdit = () => {
        appTools.displayInput("Commentaire", "Inscrire un commentaire", InputType.string, true).then((val) => {
            setCommentary(val as string);
        }).catch(() => {
            // l'utilisateur a annulé
        })
    }

    useEffect(() => {
        if(!isCenterTitle){
            setHeaderCss({textAlign: "left", fontWeight: "bold", marginTop: "0px", alignSelf: "flex-start"});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCenterTitle])

    const triggerDelete = () => {
        setCommentary(undefined);
    }

    return (
        <div className="comment-view-main">
            <p className="comment-view-title" style={headerCss}>Commentaire</p>
            {commentary === undefined ? 
                (<p className="comment-view-txt comment-view-no-comment">Pas de commentaire</p>) : 
                (<p className="comment-view-txt">{commentary}</p>)}
            <div className="comment-view-button-container">
                <div className="comment-view-button comment-view-button-edit">
                    <img src={EditWhiteImg} alt="bouton edition" onClick={triggerEdit} />
                </div>
                <div className="comment-view-button comment-view-button-delete">
                    <img src={TrashWhiteImg} alt="bouton edition" onClick={triggerDelete} />
                </div>
            </div>
        </div>
    )
}

export default CommentaryView;