import React, { createRef } from "react";
import './DisplayElement.css';

export type DisplayElementProps = {
    title: string,
    text: string,
    onOk: () => void,
}

const DisplayElement = ( props: DisplayElementProps ) => {
    const {title, text, onOk } = props;

    // créer un tableau de text en fonction des sauts de ligne
    const textArray = text.split("\n");

    const mainRef = createRef<HTMLDivElement>();
    const wrapperRef = createRef<HTMLDivElement>();

    const triggerOnClose = () => {
        if(mainRef.current && wrapperRef.current){
            mainRef.current!.style.animationName = "fadeOut";
            wrapperRef.current!.style.animationName = "UpToBottom";
            mainRef.current!.scrollTo({top: 0, behavior: "smooth"});
            setTimeout(() => {
                if(mainRef.current){
                    mainRef.current!.style.opacity = "0";
                }
                onOk();
            },400);
        }else{
            onOk();
        }
    }

    return (
        <div ref={mainRef} className="display-element-main">
            <div className="display-element-empty-space" onClick={() => triggerOnClose()}></div>
            <div ref={wrapperRef} className="display-element-wrapper">
                <div className="display-element-container">
                    <div className="display-element-round-square" />
                    <p className="display-element-title">{title}</p>

                    {textArray.map((txt, index) => {
                        if(index === textArray.length - 1){
                            return (
                                <p key={index} className="display-element-txt">{txt}</p>
                            )
                        }
                        return (
                            <p key={index} className="display-element-txt" style={{marginBottom:0}}>{txt}</p>
                        )
                    })}

                    <div className="display-element-button display-element-button-valid" onClick={() => {triggerOnClose()}}>
                        <p>OK</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DisplayElement;