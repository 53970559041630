import React, { CSSProperties, useEffect, useState } from 'react';
import './RoleState.css';
import { Role, getRoleLabel } from '../../types.ts';
import { CheckWhiteImg } from '../../images/index.js';
import LoadingSpinner from './LoadingSpinner.tsx';
import { BLACK, GREEN_PRIMARY, WHITE } from '../../const.ts';

type RoleStateProps = {
    isSigned: boolean,
    role: Role
}

const RoleState = (props: RoleStateProps) => {
    const {isSigned, role} = props;
    const [iconContainerCss, setIconContainerCss] = useState<CSSProperties>({});
    const [txtCss, setTxtCss] = useState<CSSProperties>({});

    useEffect(() => {
        if(isSigned){
            setIconContainerCss({backgroundColor:GREEN_PRIMARY});
            setTxtCss({color:GREEN_PRIMARY});
        }else{
            setIconContainerCss({backgroundColor:WHITE});
            setTxtCss({color:BLACK});
        }
    }, [isSigned]);

    return (
        <div className='rolestate-main'>
            <div className='rolestate-icon-container' style={iconContainerCss}>
                {isSigned && (
                    <img alt='' src={CheckWhiteImg} />
                )}
                {!isSigned && (
                    <LoadingSpinner />
                )}
            </div>
            <p style={txtCss}>{getRoleLabel(role)}</p>
        </div>
    );
}

export default RoleState;