import React, { CSSProperties } from "react";
import './ConsultTable.css';
import { ConsultTableElement, ConsultTableHeaderIcon } from "../compTypes.ts";
import { ConsultTableCalendarImg, ConsultTableMeasureImg, ConsultTablePersonImg } from "../index.js";
import { AppTools } from "../../types.ts";
import ConsultTableValue from "./ConsultTableValue.tsx";

type ConsultTableProps = {
    title: string,
    backgroundTitleCss: string,
    tables: ConsultTableElement[]
    values: string[][],
    appTools: AppTools
}   

const ConsultTable = (props: ConsultTableProps) => {
    const { title, backgroundTitleCss, tables, values, appTools } = props;

    const getIcon = (icon: ConsultTableHeaderIcon): string => {
        switch(icon){
            case ConsultTableHeaderIcon.DATE:
                return ConsultTableCalendarImg;
            case ConsultTableHeaderIcon.PERSON:
                return ConsultTablePersonImg;
            case ConsultTableHeaderIcon.MEASURE:
                return ConsultTableMeasureImg;
            default:
                return "";
        }
    }

    return (
        <div className="consultTable-main">
            <div className="consultTable-header" style={{background: backgroundTitleCss}}>
                <h1 className="consultTable-title">{title}</h1>
            </div>

            {tables.map((table, indexTable) => {
                return (
                    <div key={indexTable} className="consultTable-table-container">
                        <div className="consultTable-table-header" style={{background: table.titleBackgroundCss}}>
                            <h1 className="consultTable-title">{table.titleTable}</h1>
                        </div>

                        {table.headers.map((header, indexElement) => {
                            // effectue un ternaire pour alterner les couleurs
                            var color : CSSProperties;
                            if(indexElement % 2 === 0){
                                color = {backgroundColor: "#FFFFFF"};
                            } else {
                                color = {backgroundColor: "#EEEEEE"};
                            }

                            return (
                                <div style={color} key={indexElement} className="consultTable-table-element">
                                    <img alt="icon table" src={getIcon(header.icon)} className="consultTable-table-icon" />
                                    <p className="consultTable-table-element-header">{header.title}</p>
                                    <ConsultTableValue
                                        value={values[indexTable][indexElement]}
                                        header={header}
                                        appTools={appTools}
                                    />
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    );
};

export default ConsultTable;