import React, { createRef, CSSProperties, useEffect, useState } from "react";
import './EditEquipmentsView.css';
import { AppTools, EquipmentsChanging, InputType, MessageViewType } from "../../types.ts";
import { EPCResponse, EPIResponse } from "../../api/responses";
import { GoBack } from "..";
import { useRequest } from "../../hooks";
import { BASE_URL, GREEN_PRIMARY, RED_PRIMARY, YELLOW_PRIMARY } from "../../const.ts";
import { AddWhiteImg, GoBackWhiteImg, TrashWhiteImg } from "../../images";

const cacheKeys = "equipmentsCache";
const cacheTick = 1000 * 60 * 60 * 24; // 1 jour

type EditEquipmentsViewProps = {
    appTools: AppTools,
    necessaryEPIs?: EPIResponse[],
    necessaryEPCs?: EPCResponse[],
    equisChanging: EquipmentsChanging,
    setEquisChanging: React.Dispatch<React.SetStateAction<EquipmentsChanging>>,
    customToken?: string
}

type EquipmentsCache = {
    epis: EPIResponse[],
    epcs: EPCResponse[],
    dateCacheTick: number
}

type TypeEPIDisplay = {
    epiId: number,
    epiName: string,
    isAdding: boolean,
    isRemoving: boolean
}

type TypeEPCDisplay = {
    epcId: number,
    epcName: string,
    isAdding: boolean,
    isRemoving: boolean
}

const styleTxtToRemove: CSSProperties = {
    color: RED_PRIMARY,
    textDecoration: "line-through"
}

const styleTxtToAdd: CSSProperties = {
    color: GREEN_PRIMARY,
    fontWeight: "bold",
}

const EditEquipmentsView = (props: EditEquipmentsViewProps) => {
    const { appTools, necessaryEPIs, necessaryEPCs, equisChanging, setEquisChanging, customToken } = props;
    const mainRef = createRef<HTMLDivElement>();
    const [allEpis, setAllEpis] = useState<EPIResponse[] | undefined>();
    const [allEpcs, setAllEpcs] = useState<EPCResponse[] | undefined>();

    const [episToDisplay, setEpisToDisplay] = useState<TypeEPIDisplay[]>([]);
    const [epcsToDisplay, setEpcsToDisplay] = useState<TypeEPCDisplay[]>([]);

    const [, , get] = useRequest(BASE_URL, appTools, customToken);

    const triggerHideEditor = (errorMsg?: string) => {
        mainRef.current!.style.animationName = "slideout";
        setTimeout(() => {
            if(mainRef.current){
                mainRef.current!.style.opacity = "0";
            }
            appTools.popDisplay();

            if(errorMsg){
                appTools.showMessage(MessageViewType.Error, errorMsg);
            }
        },400);
    }

    useEffect(() => {
        if(localStorage.getItem(cacheKeys) !== null){
            const cache: EquipmentsCache = JSON.parse(localStorage.getItem(cacheKeys)!);
            if(cache.epis && cache.epcs && cache.dateCacheTick + cacheTick > Date.now()){
                setAllEpis(cache.epis);
                setAllEpcs(cache.epcs);
                return;
            }
        }

        get<EPIResponse[]>("/api/equipments/epis").then((epis) => {
            setAllEpis(epis.data);

            get<EPCResponse[]>("/api/equipments/epcs").then((epcs) => {
                setAllEpcs(epcs.data);

                // stockage en cache
                const cache: EquipmentsCache = {
                    epis: epis.data,
                    epcs: epcs.data,
                    dateCacheTick: Date.now()
                }
                localStorage.setItem(cacheKeys, JSON.stringify(cache));
            }).catch(() => {
                triggerHideEditor("Erreur lors de la récupération des EPCs");
            })
        }).catch(() => {
            triggerHideEditor("Erreur lors de la récupération des EPIs");
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        updateDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allEpis, allEpcs, necessaryEPIs, necessaryEPCs])

    const updateDisplay = (newChange?: EquipmentsChanging) => {
        const currentChange = newChange ? newChange : equisChanging;
        if(allEpis !== undefined && allEpcs !== undefined){
            let newEpisToDisplay: TypeEPIDisplay[] = [];
            let newEpcsToDisplay: TypeEPCDisplay[] = [];

            if(necessaryEPIs){
                newEpisToDisplay = necessaryEPIs.map((epi) => {
                    return {
                        epiId: epi.epiId,
                        epiName: epi.epiName,
                        isAdding: false,
                        isRemoving: currentChange.epis.findIndex((x) => x.epiId === epi.epiId && !x.toAdd) !== -1
                    }
                });
            }
            if(necessaryEPCs){
                newEpcsToDisplay = necessaryEPCs.map((epc) => {
                    return {
                        epcId: epc.epcId,
                        epcName: epc.epcName,
                        isAdding: false,
                        isRemoving: currentChange.epcs.findIndex((x) => x.epcId === epc.epcId && !x.toAdd) !== -1
                    }
                });
            }

            currentChange.epis.forEach((epi) => {
                if(epi.toAdd){
                    newEpisToDisplay.push({
                        epiId: epi.epiId,
                        epiName: allEpis.find((x) => x.epiId === epi.epiId)!.epiName,
                        isAdding: true,
                        isRemoving: false
                    });
                }
            })

            currentChange.epcs.forEach((epc) => {
                if(epc.toAdd){
                    newEpcsToDisplay.push({
                        epcId: epc.epcId,
                        epcName: allEpcs.find((x) => x.epcId === epc.epcId)!.epcName,
                        isAdding: true,
                        isRemoving: false
                    });
                }
            })
            setEpisToDisplay(newEpisToDisplay);
            setEpcsToDisplay(newEpcsToDisplay);
        }
    }

    const addEpi = (epiId: number) => {
        setEquisChanging((prev: EquipmentsChanging) => {
            const epiChanging = prev.epis.find((x) => x.epiId === epiId);
            const indexNecessary = necessaryEPIs ? necessaryEPIs.findIndex((x) => x.epiId === epiId) : -1;
            
            // cas où l'EPI n'est pas dans la liste nécessaire et n'est pas déjà ajouté
            if(epiChanging === undefined && indexNecessary === -1){
                prev.epis.push({
                    epiId: epiId,
                    toAdd: true
                })
            // cas où l'EPI est dans la liste nécessaire mais indiqué comme supprimé
            }else if(indexNecessary !== -1 && epiChanging !== undefined && epiChanging.toAdd === false){
                const index = prev.epis.findIndex((x) => x.epiId === epiId);
                // supprime le changement
                prev.epis.splice(index, 1);
            }
            updateDisplay(prev);

            return prev;
        });
    }

    const removeEpi = (epiId: number) => {
        setEquisChanging((prev: EquipmentsChanging) => {
            const indexNecessary = necessaryEPIs ? necessaryEPIs.findIndex((x) => x.epiId === epiId) : -1;
            const epiChanging = prev.epis.find((x) => x.epiId === epiId);
            // cas où l'EPI est dans la liste nécessaire et n'est pas en cours de suppression
            if(indexNecessary !== -1 && epiChanging === undefined){
                prev.epis.push({
                    epiId: epiId,
                    toAdd: false
                })
            }else if(epiChanging !== undefined && epiChanging.toAdd){
                const index = prev.epis.findIndex((x) => x.epiId === epiId);
                // supprime le changement
                prev.epis.splice(index, 1);
            }
            updateDisplay(prev);

            return prev;
        });
    }

    const addEpc = (epcId: number) => {
        setEquisChanging((prev: EquipmentsChanging) => {
            const epcChanging = prev.epcs.find((x) => x.epcId === epcId);
            const indexNecessary = necessaryEPCs ? necessaryEPCs.findIndex((x) => x.epcId === epcId) : -1;
            
            // cas où l'EPI n'est pas dans la liste nécessaire et n'est pas déjà ajouté
            if(epcChanging === undefined && indexNecessary === -1){
                prev.epcs.push({
                    epcId: epcId,
                    toAdd: true
                })
            // cas où l'EPI est dans la liste nécessaire mais indiqué comme supprimé
            }else if(indexNecessary !== -1 && epcChanging !== undefined && epcChanging.toAdd === false){
                const index = prev.epcs.findIndex((x) => x.epcId === epcId);
                // supprime le changement
                prev.epcs.splice(index, 1);
            }
            updateDisplay(prev);

            return prev;
        });
    }

    const removeEpc = (epcId: number) => {
        setEquisChanging((prev: EquipmentsChanging) => {
            const indexNecessary = necessaryEPCs ? necessaryEPCs.findIndex((x) => x.epcId === epcId) : -1;
            const epcChanging = prev.epcs.find((x) => x.epcId === epcId);
            // cas où l'EPC est dans la liste nécessaire et n'est pas en cours de suppression
            if(indexNecessary !== -1 && epcChanging === undefined){
                prev.epcs.push({
                    epcId: epcId,
                    toAdd: false
                })
            }else if(epcChanging !== undefined && epcChanging.toAdd){
                const index = prev.epcs.findIndex((x) => x.epcId === epcId);
                // supprime le changement
                prev.epcs.splice(index, 1);
            }
            updateDisplay(prev);

            return prev;
        });
    }

    const triggerAddNewEpi = () => {
        appTools.displayInput("Ajouter un nouvel EPI", "", InputType.select, undefined, 
            [{value: -1, label: "-- Selectionner EPI --"}].concat(allEpis!.filter(epi => episToDisplay.findIndex(x => x.epiId === epi.epiId) === -1).map((epi) => {
                return {value: epi.epiId, label: epi.epiName}
            }))
        ).then((epiId) => {
            if(epiId !== -1){
                addEpi(epiId);
            }
        }).catch(() => {
            // rien
        })
    }

    const triggerAddNewEpc = () => {
        appTools.displayInput("Ajouter un nouvel EPC", "", InputType.select, undefined, 
            [{value: -1, label: "-- Selectionner EPC --"}].concat(allEpcs!.filter(epc => epcsToDisplay.findIndex(x => x.epcId === epc.epcId) === -1).map((epc) => {
                return {value: epc.epcId, label: epc.epcName}
            }))
        ).then((epcId) => {
            if(epcId !== -1){
                addEpc(epcId);
            }
        }).catch(() => {
            // rien
        })
    }

    return (
        <div ref={mainRef} className="editEquipments-main">
            <GoBack onGoBack={triggerHideEditor} />

            <div className="editEquipments-wrapper">
                <div className="editEquipments-container">
                    <p className="editEquipments-header">Éditeur d'équipements</p>

                    <p className="editEquipments-subheader">
                        Ce menu permet d'ajouter ou de retirer des équipements. 
                        Ne s'applique qu'au récapitulatif. Les modifications effectuées ici n'impacterons pas 
                        le permis principal ni les annexes.</p>

                    <p className="editEquipments-title">Équipements de protection individuelle</p>
                    <div className="editEquipments-separator" />

                    {episToDisplay.map((epi, index) => (
                        <div key={index} className="editEquipments-equip-container">
                            {epi.isAdding && <p style={styleTxtToAdd}>{epi.epiName}</p>}
                            {epi.isRemoving && <p style={styleTxtToRemove}>{epi.epiName}</p>}
                            {!epi.isAdding && !epi.isRemoving && <p>{epi.epiName}</p>}
                            
                            {(epi.isAdding || !epi.isRemoving) && (
                                <div onClick={() => removeEpi(epi.epiId)} 
                                    className="editEquipments-equip-button" 
                                    style={{backgroundColor: RED_PRIMARY}}>
                                    <img alt="Supprimer EPI" src={TrashWhiteImg} />
                                </div>
                            )}

                            {epi.isRemoving && (
                                <div onClick={() => addEpi(epi.epiId)} 
                                    className="editEquipments-equip-button" 
                                    style={{backgroundColor: YELLOW_PRIMARY}}>
                                    <img alt="Remettre EPI" src={GoBackWhiteImg} />
                                </div>
                            )}
                        </div>
                    ))}

                    <div onClick={triggerAddNewEpi} className="editEquipments-add-equip-button">
                        <img alt="bouton d'ajout EPI" src={AddWhiteImg} />
                        <p>Ajouter EPI</p>
                    </div>

                    <p className="editEquipments-title">Équipements de protection collective</p>
                    <div className="editEquipments-separator" />

                    {epcsToDisplay.map((epc, index) => (
                        <div key={index} className="editEquipments-equip-container">
                            {epc.isAdding && <p style={styleTxtToAdd}>{epc.epcName}</p>}
                            {epc.isRemoving && <p style={styleTxtToRemove}>{epc.epcName}</p>}
                            {!epc.isAdding && !epc.isRemoving && <p>{epc.epcName}</p>}
                            
                            {(epc.isAdding || !epc.isRemoving) && (
                                <div onClick={() => removeEpc(epc.epcId)} 
                                    className="editEquipments-equip-button" 
                                    style={{backgroundColor: RED_PRIMARY}}>
                                    <img alt="Supprimer EPI" src={TrashWhiteImg} />
                                </div>
                            )}

                            {epc.isRemoving && (
                                <div onClick={() => addEpc(epc.epcId)} 
                                    className="editEquipments-equip-button" 
                                    style={{backgroundColor: YELLOW_PRIMARY}}>
                                    <img alt="Remettre EPI" src={GoBackWhiteImg} />
                                </div>
                            )}
                        </div>
                    ))}

                    <div onClick={triggerAddNewEpc} className="editEquipments-add-equip-button">
                        <img alt="bouton d'ajout EPC" src={AddWhiteImg} />
                        <p>Ajouter EPC</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditEquipmentsView;